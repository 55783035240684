<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:38
-->
<template>
	<div class="manHourVerify">
		<div class="manHourVerify-content">
			<div class="manHourVerify-search">
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">学号</span>
						<el-input class="common-row-ipt" placeholder="请输入学号" v-model="searchForm.studentId" />
					</div>
					<div class="common-row-item">
						<span class="common-row-label">姓名</span>
						<el-input class="common-row-ipt" placeholder="请输入姓名" v-model="searchForm.name" />
					</div>
					<div class="common-row-item">
						<el-button @click="search" type="primary">查询</el-button>
						<el-button @click="reset">重置</el-button>
					</div>
				</div>
			</div>
			<div class="manHourVerify-table">
				<el-table :data="tableData" border>
					<el-table-column width="65px" type="index" label="序号">
					</el-table-column>
					<el-table-column prop="studentId" label="学号">
					</el-table-column>
					<el-table-column prop="name" label="姓名">
					</el-table-column>
					<el-table-column prop="source" label="来源">
					</el-table-column>
					<el-table-column prop="status" label="状态">
						<template slot-scope="scope">
							<span
								:class="{'action-status-red': scope.row.status == 2}">{{scope.row.status == 1?"审理中": scope.row.status == 2?"审核不通过": scope.row.status == 3?"开具成功":""}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="operator" label="操作人">
					</el-table-column>
					<el-table-column prop="operatorTime" label="操作时间">
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('detail', scope)">详情</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		listIssueHistoryList
	} from "@/api/electronic";
	export default {
		data() {
			return {
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				searchForm: {}
			};
		},
		mounted() {
			this.getIssueHistoryList()
		},
		methods: {
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.getIssueHistoryList();
			},
			getIssueHistoryList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
				}
				if (this.searchForm.studentId) {
					this.$set(params, "studentId", this.searchForm.studentId)
				}
				if (this.searchForm.name) {
					this.$set(params, "name", this.searchForm.name)
				}
				listIssueHistoryList(params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			search() {
				this.pageIndex = 1;
				this.tableData = [];
				this.getIssueHistoryList();
			},
			reset() {
				this.searchForm = {}
				this.pageIndex = 1;
				this.tableData = [];
				this.getIssueHistoryList();
			},
			actionFn(type, scope) {
				switch (type) {
					case "detail":
						this.$router.push({
							path: `/issueHistory/detail?detailId=${scope.row.id}&&id=${scope.row.id}`
						})
						break;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.el-button {
		height: 36px;
	}

	.action-status-red {
		color: #E63757;
	}

	.manHourVerify {
		.manHourVerify-content {
			padding: 20px 20px;

			.manHourVerify-search {
				.common-row {
					margin: 16px 0;

					.common-row-item {
						margin-right: 20px;

						.common-row-ipt {
							flex: 1;
							height: 36px;
							line-height: 36px;
							border: 1px solid #EDF1F7;
							border-left: 0;
						}
					}
				}
			}

			.manHourVerify-table {
				margin-top: 20px;
			}
		}
	}
</style>
